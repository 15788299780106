import "../officekarte-neo-kit/officekarte-neo-kit.esm";
import { Turbo } from "@hotwired/turbo-rails";
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

Turbo.session.drive = false;

declare global {
  interface Window {
    initializeSentry: (applicationId: string, environment: string) => void;
    resolveWhenSetPropertyToElement: (uniqueElementSelector: string, propertyName: string) => Promise<Element>
  }
}

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll("form").forEach((element) => {
    element.addEventListener("submit", (event) => {
      const target = event.target;

      if (target instanceof Element) {
        target.querySelectorAll("input[type=submit],button[type=submit]").forEach((submitButton) => {
          if (submitButton instanceof HTMLInputElement) {
            submitButton.disabled = true;

            setTimeout(() => {
              submitButton.disabled = false;
            }, 3000);
          }
        });
      }
    });
  });
});

window.initializeSentry = (applicationId: string, environment: string): void => {
  Sentry.init({
    dsn: "https://5d3e727ec0dc4e9692405ad36f598ba6@o54978.ingest.sentry.io/4504541652713472",
    integrations: [new BrowserTracing()],
    enabled: environment === "production" || environment === "staging" || environment === "test",
    environment
  });

  Sentry.configureScope(scope => {
    scope.setTag('application', applicationId);
  })
}

let detectIsProxiedError = false;
window.addEventListener("unhandledrejection", (event) => {
  // NOTE: isProxiedエラーが起きると何も表示されなくなるので、リロードする
  // FIXME: ここで出しているmessageの数とエラーで検知した数を比較し問題なければ、エラーがあがらないようにignoreする（cf. https://git.l.twogate.net/nrm/officekarte-neo/officekarte-neo-server/-/merge_requests/1001)
  if (/Cannot read properties of undefined \(reading 'isProxied'\)/.test(event.reason.message)) {
    const urlSearchParam = new URLSearchParams(window.location.search);

    if(!urlSearchParam.has('reloaded')) {
      urlSearchParam.set('reloaded', 'true')
      if (!detectIsProxiedError) {
        Sentry.captureMessage("Reload for fix error (cf. gitlab issue #1205)")
      }
      window.location.href = window.location.href + '?' + urlSearchParam.toString()
    } else {
      if (!detectIsProxiedError) {
        Sentry.captureMessage("Not fix #1205 error despite reloading.")
      }
    }
    detectIsProxiedError = true;
  }
})

/**
 * 要素に指定されたプロパティが設定されたタイミングでresolveするPromiseを返す。
 * 1秒以内にプロパティがセットされなければ、エラーを返す
 */
window.resolveWhenSetPropertyToElement = (uniqueElementSelector: string, propertyName: string) => {
  const maxCallCount = 200;
  let callCount = 0;
  return new Promise(async (resolve, reject) => {
    const element = document.querySelector(uniqueElementSelector);
    if (element === null) {
      reject(new Error(`Cannot find ${uniqueElementSelector}`))
      return;
    }

    while (true) {
      const sleep = (millsecond: number) => new Promise(resolve => setTimeout(resolve, millsecond))
      await sleep(5)
      if(callCount > maxCallCount) {
        reject(new Error(`Could not detect ${propertyName} was set to ${uniqueElementSelector}`));
        break;
      } else {
        callCount++;
        if ((element as any)[propertyName] !== undefined) {
          resolve(element)
        }
      }
    }
  })
}
